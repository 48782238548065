<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-card>
        <v-layout
          class="d-flex flex-lg-row flex-md-column flex-sm-column"
          wrap
          pa-3
        >
          <v-flex lg12 md12 sm12 xs12>
            <h-currency-input
              v-model="selectedItem.QuantityMin"
              :decimal="0"
              label="Số sản phẩm (Min)"
              class="input-group--focused"
            ></h-currency-input>
          </v-flex>
          <v-flex lg12 md12 sm12 xs12>
            <h-currency-input
              v-model="selectedItem.QuantityMax"
              :decimal="0"
              label="Số sản phẩm (Max)"
              class="input-group--focused"
            ></h-currency-input>
          </v-flex>
          <v-flex lg12 md12 sm12 xs12>
            <h-currency-input
              v-model="selectedItem.PackageA"
              :decimal="0"
              label="Tiền phí gói A"
              class="input-group--focused"
            ></h-currency-input>
          </v-flex>
          <v-flex lg12 md12 sm12 xs12>
            <h-currency-input
              v-model="selectedItem.PackageB"
              :decimal="0"
              label="Tiền phí gói B"
              class="input-group--focused"
            ></h-currency-input>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      loading: (state) => state.feeCheck.loading,
      selectedItem: (state) => state.feeCheck.selected,
    }),
    modifyId() {
      return this.$route.params.Pid;
    },
  },
  watch: {
    modifyId() {
      this.refreshData();
    },
  },
  methods: {
    refreshData() {
      if (this.modifyId > 0) {
        this.$store.dispatch("feeCheck/getDetail", this.modifyId);
      } else {
        this.$store.commit("feeCheck/setDetail", {
          data: {
            ID: 0,
            QuantityMin: 0,
            QuantityMax: 0,
            PackageA: 0,
            PackageB: 0,
          },
        });
      }
    },
    saveData() {
      this.$store.dispatch("feeCheck/setDetail", {
        data: {
          ...this.selectedItem,
          ModifiedBy: this.$store.state.authentication.user.username,
        },
        id: this.selectedItem.ID,
      });
      window.getApp.$emit("APP_NAVIGATE", "FeeCheckList");
    },
  },
  mounted() {
    this.refreshData();
    this.$root.$emit("REQUEST_ROOT_OPTION", {});
    this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
  },
};
</script>